@charset 'UTF-8';
@import "/Users/Phat/aliveprojects/htdocs/oxjack/src/scss/utility/_media-queries.scss";
@import "/Users/Phat/aliveprojects/htdocs/oxjack/src/scss/utility/_mixin.scss";


.recruit {
  .sec {
    .tit {
      @include SP {
        text-align: center;
      }
      strong {
        color: $blue;
        display: inline-block;
        vertical-align: top;
        font-family: $let;
        @include font-size(58);
        letter-spacing: 0.1em;
        line-height: 64px;
        @include IPAD {
          @include font-size(50);
        }
        @include SP {
          @include font-size(33);
          line-height: 37px;
        }
      }
      span {
        display: inline-block;
        vertical-align: top;
        @include font-size(45);
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 60px;
        @include IPAD {
          @include font-size(38);
        }
        @include SP {
          @include font-size(26);
          line-height: 32px;
        }
      }
      &--white {
        strong,
        span {
          color: $white;
        }
      }
    }
    .txt {
      @include font-size(24);
      font-weight: bold;
      text-align: justify;
      padding: 6px 0 16px;
      letter-spacing: 0.05em;
      line-height: 35px;
      @include IPAD {
        @include font-size(20);
      }
      @include SP {
        @include font-size(15);
        text-align: center;
        padding: 0 0 10px;
        line-height: 21px;
      }
      &--white {
        color: $white;
      }
    }
    .btn {
      display: block;
      @include font-size(20);
      max-width: 218px;
      font-weight: 500;
      text-align: center;
      padding: 6px 0;
      letter-spacing: 0.05em;
      line-height: 22px;
      position: relative;
      @include IE {
        padding: 6px 0 8px;
      }
      @include SP {
        @include font-size(14);
        padding: 2px 0;
        width: calc(100% - 15px);
        max-width: unset;
        margin: 20px auto 0;
      }
      span {
        display: block;
        position: relative;
        z-index: 2;
        @include transition_c(all 0.3s ease-out);
      }
      &--blue {
        @include HOVER {
          &:hover {
            span {
              background-image: url(../../img/common/icon/ico_arrow_right_white.png);
            }
          }
        }
        span {
          background: url(../../img/common/icon/ico_arrow_right_blue.png) right 14px center/8px 20px no-repeat;
          @include SP {
            background: url(../../img/common/icon/ico_arrow_right_blue_sp.png) right 12px center/6px 17px no-repeat;
          }
        }
      }
      &--white {
        @include HOVER {
          &:hover {
            span {
              background-image: url(../../img/common/icon/ico_arrow_right_blue.png);
            }
          }
        }
        span {
          background: url(../../img/common/icon/ico_arrow_right_white.png) right 14px center/8px 20px no-repeat;
          @include SP {
            background: url(../../img/common/icon/ico_arrow_right_white_sp.png) right 12px center/6px 17px no-repeat;
          }
        }
      }
    }
    .table {
      @include flexbox();
      @include flex-wrap();
      width: 100%;
      .left {
        @include flexbox();
        @include flex-wrap();
        width: 60%;
        padding-right: 16px;
        @include SP {
          padding-right: 8px;
        }
        &--bottom {
          @include align-items(flex-end);
        }
      }
      .right {
        @include flexbox();
        width: 40%;
        position: relative;
        &--bottom {
          @include align-items(flex-end);
          padding-bottom: 16px;
          @include SP {
            padding-bottom: 8px;
          }
        }
      }
      .full {
        @include flexbox();
        width: 100%;
      }
      .img {
        &--first {
          margin-bottom: 16px;
          @include SP {
            margin-bottom: 8px;
          }
        }
        &--last {
          width: 70%;
          max-width: 265px;
        }
        &--left {
          margin-left: auto;
        }
        &--middle {
          position: absolute;
          top: 40%;
          @include SP {
            top: 38%;
          }
        }
        &--center {
          width: 40%;
          max-width: 265px;
          margin: 0 auto;
        }
      }
    }
  }

  .intro {
    background: url(../../img/recruit/intro_ms.jpg) center/cover no-repeat;
    height: 100vh;
    padding: 0 15px;
    position: relative;
    @include SP {
      background-image: url(../../img/recruit/intro_ms_sp.jpg);
    }
    &__content {
      position: absolute;
      width: 100%;
      max-width: 1115px;
      top: 50%;
      left: 50%;
      @include transform_c(translate(-50%, -50%));
      @include SP {
        top: 39%;
      }
    }
    &__txt {
      img {
        width: 100%;
      }
    }
    &__scroll {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 20px;
      left: 0;
      a {
        background: url("../../img/recruit/img_scroll.png") center/contain no-repeat;
        display: inline-block;
        width: 59px;
        height: 70px;
        @include SP {
          background-image: url("../../img/recruit/img_scroll_sp.png");
          width: 34px;
          height: 26px;
        }
      }
    }
  }

  .white {
    background: $whiteDark;
    &__container {
      width: 100%;
      max-width: 1075px;
      @include font-size(0);
      padding: 76px 10px 74px;
      margin: 0 auto;
      position: relative;
      @include SP {
        padding: 16px 10px 22px;
      }
    }
    &__info {
      display: inline-block;
      vertical-align: middle;
      width: 395px;
      padding-left: 20px;
      @include SP {
        width: 100%;
        padding-left: 0;
      }
    }
    &__img {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 395px);
      @include SP {
        width: 100%;
      }
    }
  }

  .blue {
    background: $blue;
    &__container {
      width: 100%;
      max-width: 1075px;
      @include font-size(0);
      padding: 80px 10px 70px;
      margin: 0 auto;
      position: relative;
      @include SP {
        @include flexbox();
        @include flex-wrap();
        width: 100%;
        padding: 16px 10px 24px;
        .btn {
          order: 3;
        }
      }
    }
    &__img {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 395px);
      @include SP {
        order: 2;
        @include flex-direction(row);
        width: 100%;
      }
    }
    &__info {
      display: inline-block;
      vertical-align: middle;
      width: 395px;
      padding-left: 34px;
      @include SP {
        order: 1;
        @include flex-direction(row);
        width: 100%;
        padding-bottom: 10px;
        padding-left: 0;
      }
    }
  }

  .unique {
    padding: 74px 18px;
    @include SP {
      padding: 24px 18px;
    }
    &--gray {
      background: $whiteDark;
    }
    a {
      display: block;
      width: 100%;
      max-width: 552px;
      @include font-size(24);
      font-weight: bold;
      text-align: center;
      padding: 10px 0;
      margin: 0 auto;
      letter-spacing: 0.05em;
      line-height: 35px;
      position: relative;
      @include SP {
        max-width: unset;
        @include font-size(14);
        padding: 4px 0;
        line-height: 18px;
      }
      span {
        display: block;
        position: relative;
        z-index: 10;
      }
    }
  }

  #wrap {
    margin-bottom: 120px;
    @include SP {
      margin-bottom: 75px;
    }
  }
}
